import axios from 'axios'

 
const mixin = {
	data: () => ({
		
		LocationData: {host:'', hostname:'', origin:''},
		workspaceTimezone:'',
		workspaceDateFormat:'DD.MM.YYYY HH:mm'
	}),
	methods: {
		isMobile() {
			// const isMobile = window.matchMedia("only screen and (max-width: 760px)")
			// console.log( isMobile.matches )
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				return false;
			} else {
				return false;
			}
		},
		
		getlogo() {
			
			let that = this
			that.LocationData.host		= window.location.host
			that.LocationData.hostname  = window.location.hostname
			that.LocationData.origin 	= window.location.origin
			let Location = that.LocationData
			// console.log(location)
			axios.post( '/auth/getlogo', Location ).then(resp => {
				// console.log(resp.data.logo)
				// <link rel="icon" href="/img/ohmylead-favicon.png" type="image/png" sizes="16x16" />
				
				that.$store.state.WhiteLabel  = resp.data.whitelabel
				that.$store.state.IsAdmin	  = resp.data.admin
				that.$store.state.CompanyName = resp.data.company_name
				
				let favicon = resp.data.favicon != null ? resp.data.favicon : ''
				if( resp.data.whitelabel ) {
					that.$store.state.DefaultLogo = resp.data.logo
				} else {
					favicon = 'https://console.ohmylead.com/img/ohmylead-favicon.png'
					that.$store.state.DefaultLogo	= 'https://console.ohmylead.com/img/svg/logo.svg'
				}
				
				if( favicon != '' ) {
					var link 	= document.querySelector("link[rel*='icon']") || document.createElement('link');
					link.type 	= 'image/png';
					link.rel 	= 'icon';
					link.href 	= favicon // '/img/ohmylead-favicon.png';
					link.sizes 	= '16x16';
					document.getElementsByTagName('head')[0].appendChild(link);
				}
				
				let title      = resp.data.company_name != '' ? resp.data.company_name +  ' | ' : ''
				document.title = title + document.title || '';

				// if(this.isMobile && that.$store.whitelabel){
				// 	window.location = resp.data.url_mobile 
				// }else if(this.isMobile && !that.$store.whitelabel){
				// 	window.location = 'https://google.com'
				// }
				
			}).catch(err => {
				console.log(err)
				that.DefaultLogo = 'img/svg/logo.svg'
			})
		},
		
		notifyMe(message, variant) {
			this.$notify({
				group	: 'foo',
				// title: 'Important message',
				text	: message,
				type	: variant,
				// duration: 500000,
				// speed	: 1000
			});
		},
		
		
		
		
	},
	filters: {
			
	},
	mounted() {
		let ClientSelected 	= JSON.parse( localStorage.getItem('oml-client') ) 
		if(ClientSelected){
			this.workspaceTimezone 	= ClientSelected.timezone
		}
	},
		
		
	}
export default mixin;
